<template>
  <div v-loading="loading">
    <el-card style="margin-bottom:80px">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="(v,i) in tabsList" :key="i" :label="v.label" :name="v.value"></el-tab-pane>
      </el-tabs>
      <div class="form" style="margin-top:0px">
        <div v-if="activeName=='1'">
          <el-form :model="chanceForm" ref="chanceForm" :rules="chanceRules" label-width="120px" key="form1">
            <el-form-item label="抽中概率：" prop="">
              <el-table :data="chanceForm.tablelist" style="width:500px">
                <el-table-column prop="CardLevelValue" label="卡面" width="150"></el-table-column>
                <el-table-column prop="" label="抽中概率">
                  <template slot-scope="scope">
                    <el-form-item :prop="'tablelist.'+scope.$index+'.DrawCardRate'" :rules="chanceRules.DrawCardRate" style="margin:20px 0">
                      <el-input oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"
                        v-model="scope.row.DrawCardRate" style="width:200px">
                        <template slot="append">%</template>
                      </el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item label="未抽中概率：" prop="">
              <el-input :value="losingLottery<0?0:losingLottery" :disabled="true" style="width:200px"></el-input> <span class="font-14 color-999999 margin-left-10">%</span>
              <div class="font-14 color-999999">未抽中概率=100%-所有卡面抽中概率之和</div>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="activeName=='2'">
          <el-form :model="groupForm" ref="groupForm" :rules="groupRules" label-width="100px" key="form2">
            <el-form-item :label="'群聊'+(i+1)" v-for="(v,i) in groupForm.list" label-width="80" :key="i" required>
              <div class="flex flex-align-end gap-10">
                <div class="groupBox">
                  <el-form-item label="选择群聊：" :prop="'list.'+i+'.QyWeixinRealGroupId'" :rules="groupRules.QyWeixinRealGroupId">
                    <el-select v-model="v.QyWeixinRealGroupId" filterable style="width:350px">
                      <el-option v-for="(x,y) in groupList" :key="y" :value="x.Id" :label="x.GroupName" style="max-width:350px"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="群二维码：" class="margin-top-20" :prop="'list.'+i+'.GroupQrCode'"
                    :rules="groupRules.GroupQrCode">
                    <el-upload ref="uploader" :action="uploadUrl" accept="image/png,image/jpg,image/jpeg" :key="'upload'+i"
                      :on-success="(e)=>{onSuccess(e,i)}" class="qrCodeBox-10"
                      :before-upload="beforeUpload"
                      :show-file-list="false" list-type="picture-card">
                      <div v-if="v.GroupQrCode" class="EditassociateData-uploadImg" @click.stop="{}">
                        <img :src="imgApi+v.GroupQrCode" style="vertical-align:inherit" alt="">
                        <div class="mask">
                          <span>重新选择</span>
                          <el-upload ref="" :key="'upload2'+i" class="again" :action="uploadUrl" accept="image/png,image/jpg,image/jpeg"
                            :on-success="(e)=>{onSuccess(e,i)}"
                            :before-upload="beforeUpload"
                            :show-file-list="false" list-type="picture-card">
                          </el-upload>
                        </div>
                      </div>
                      <i class="el-icon-plus" v-else></i>
                    </el-upload>
                  </el-form-item>
                </div>
                <el-button type="text" style="color:#f56c6c" @click="delGroup(i)">删除群聊</el-button>
              </div>
            </el-form-item>
            <el-button type="primary" @click="addGroup" v-if="groupForm.list.length<20">添加群聊</el-button>
          </el-form>
        </div>
        <div v-if="activeName=='3'">
          <div class="flex flex-align-center flex-wrap gap-10">
            <div class="flex flex-align-center">
              <div class="font-label font-bold">关键字：</div>
              <el-input style="width:250px" placeholder="手机号、微信昵称" v-model.trim="keywords"></el-input>
            </div>
            <el-button type="primary" @click="handleCurrentChange(1)">查询</el-button>
            <div><el-button @click="exportList">导出</el-button></div>
          </div>
          <el-table :data="tablelist" v-loading="tableloading" class="margin-top-10">
            <el-table-column prop="" label="用户">
              <template slot-scope="scope">
                <div class="flex flex-align-center gap-10">
                  <img :src="scope.row.WxHeadUrl" style="width:40px;height:40px;border-radius:2px" alt="">
                  <div class="color-606266" style="width:0;flex:1">
                    <div class="flex-1 ellipsis">{{scope.row.WxNickname}}</div>
                    <div>{{scope.row.Phone}}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="收货信息">
              <template slot-scope="scope">
                <div>{{scope.row.ReceiveName}}({{scope.row.ReceivePhone}})</div>
                <div>
                  {{scope.row.ReceiveProvinceCityArea}}{{scope.row.ReceiveAddress}} 收
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="AddTime" label="提交时间" width="300"></el-table-column>
            <el-table-column prop="" label="操作" width="200">
              <template slot-scope="scope">
                <el-button type="text" @click="showChange(scope.row)">修改</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top:10px;text-align:right" v-if="total">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </el-card>
    <div class="bottom-button" v-if="activeName=='1'||activeName=='2'">
      <el-button type="primary" @click="save">保存</el-button>
    </div>


    <el-dialog :visible.sync="changeShow" title="修改收货地址" width="600px" custom-class="dialog-body-paddingTop-10" v-loading="changeLoading">
      <div class="flex flex-align-center">
        <div class="keyLabel start" style="width:120px;text-align:right">收货人姓名：</div>
        <el-input v-model="rowmsg.ReceiveName" placeholder="收货人姓名" maxlength="15"></el-input>
      </div>
      <div class="flex flex-align-center margin-top-20">
        <div class="keyLabel start" style="width:120px;text-align:right">收货人手机号：</div>
        <el-input v-model="rowmsg.ReceivePhone" placeholder="收货人手机号" maxlength="11"></el-input>
      </div>
      <div class="flex margin-top-20">
        <div class="keyLabel start" style="width:120px;text-align:right">收货地址：</div>
        <div style="width:100%">
          <el-cascader :props="props" :options="dataList" clearable style="width:100%" placeholder="请选择"
            :value="[rowmsg.ReceiveProvince,rowmsg.ReceiveCity,rowmsg.ReceiveArea]" @change="changeAddress" />
          <el-input class="margin-top-20" v-model="rowmsg.ReceiveAddress" type="textarea" :rows="4" maxlength="100" placeholder="详细地址"></el-input>
        </div>
      </div>
      <div style="text-align:right;margin-top:20px">
        <el-button @click="changeShow=false">关闭</el-button>
        <el-button type="primary" @click="saveAddress">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import config from "@/config"
import {
  qyweixinrealgrouppagelist,
} from "@/api/TurnTomySelf"
import {
  yilianActivitysave,
  yilianActivityinfo,
  yilianActivitygoldCardTakeAddressList,
  yilianActivitygoldCardTakeAddressEdit,
  yilianActivitycardInfo,
  yilianActivitycardSave,
} from "@/api/sv3.0.0"
import axios from "axios"
export default {
  data () {
    return {
      loading:false,
      activeName:'1',
      tabsList:[
        {value:'1',label:'抽卡概率配置'},
        {value:'2',label:'活动群聊配置'},
        {value:'3',label:'金卡兑换地址'},
      ],
      chanceForm:{
        tablelist:[
          {CardLevel:1,CardLevelValue:'R1',DrawCardRate:''},
          {CardLevel:2,CardLevelValue:'R2',DrawCardRate:''},
          {CardLevel:3,CardLevelValue:'R3',DrawCardRate:''},
          {CardLevel:4,CardLevelValue:'SR1',DrawCardRate:''},
          {CardLevel:5,CardLevelValue:'SR2',DrawCardRate:''},
          {CardLevel:6,CardLevelValue:'SR3',DrawCardRate:''},
          {CardLevel:7,CardLevelValue:'SSR1',DrawCardRate:''},
          {CardLevel:8,CardLevelValue:'SSR2',DrawCardRate:''},
          {CardLevel:9,CardLevelValue:'SSR3',DrawCardRate:''},
        ],
      },
      chanceRules:{
        DrawCardRate:[{
          validator: (rule,value,callback)=>{
            if(!String(value)||value<0||value>100){
              callback(new Error('概率设置在0%~100%间，2位小数'))
            }
            callback()
          }, 
          trigger: 'blur'
        }]
        
      },
      uploadUrl:config.UPLOAD_IMG,
      imgApi:config.IMG_BASE,
      groupForm:{
        list:[],
        Id:0
      },
      groupRules:{
        QyWeixinRealGroupId:[{
          required: true, 
          message: '请选择群聊', 
          trigger: 'blur'
        }],
        GroupQrCode:[{
          required: true, 
          message: '上传群二维码', 
          trigger: 'blur'
        }]
      },
      groupList:[],
      keywords:'',
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:0,
      rowmsg:{},
      changeShow:false,
      dataList:[],
      props: {
        label: 'name',
        value: 'name',
        children: 'child'
      },
      changeLoading:false,
    }
  },
  computed: {
    losingLottery(){
      let all = this.chanceForm.tablelist.reduce((pre,cur)=>{
        return pre += Number(cur.DrawCardRate)
      },0)
      let los = ((100 - all)*100).toFixed(0)/100
      return los
    }
  },
  mounted () {
    this.handleClick({name:this.activeName})
  },
  methods: {
    delGroup(i){
      this.groupForm.list.splice(i,1)
    },
    addGroup(){
      this.groupForm.list.push({
        Id:0,
        GroupQrCode:'',
        QyWeixinRealGroupId:''
      })
    },
    async saveCard(){
      try{
        this.loading = true
        let res = await yilianActivitycardSave({
          YiLianActivityCardSaveDtos:this.chanceForm.tablelist,
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.getCardInfo()
        }
      }finally{
        this.loading = false
      }
    },
    async getCardInfo(){
      try{
        this.loading = true
        let res = await yilianActivitycardInfo()
        if(res.IsSuccess){
          if(res.Result&&res.Result.length){
            this.chanceForm.tablelist = res.Result
          }
        }
      }finally{
        this.loading = false
      }
    },
    saveAddress(){
      if(!this.rowmsg.ReceiveName){
        this.$message.error('请输入收货人姓名')
        return
      }
      if(!this.rowmsg.ReceivePhone){
        this.$message.error('请输入收货人手机号')
        return
      }
      if(!this.rowmsg.ReceiveProvince||!this.rowmsg.ReceiveCity||!this.rowmsg.ReceiveArea||!this.rowmsg.ReceiveAddress){
        this.$message.error('请完善收货地址')
        return
      }
      this.confirmSaveAddress()
    },
    async confirmSaveAddress(){
      try{
        this.changeLoading = true
        let res = await yilianActivitygoldCardTakeAddressEdit(this.rowmsg)
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.changeShow = false
          this.gettablelist()
        }
      }finally{
        this.changeLoading = false
      }
    },
    changeAddress(e){
      [this.rowmsg.ReceiveProvince='',this.rowmsg.ReceiveCity='',this.rowmsg.ReceiveArea=''] = e
    },
    //获取地址数据
    getDataList() {
      var that = this
      axios.get('https://cdn.dkycn.cn/JSON/gdDistrict-v2.json?v='+new Date().getTime(), {})
        .then(function(response) {
          that.dataList = response.data
        })
        .catch(function(error) {})
    },
    showChange(row){
      this.rowmsg = JSON.parse(JSON.stringify(row))
      this.changeShow = true
    },
    exportList(){
      let url = location.origin + '/pc/yilianActivity/goldCardTakeAddressExport'
      url += '?Keywords=' + encodeURI(this.keywords)
      window.open(url)
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let res = await yilianActivitygoldCardTakeAddressList({
          Keywords:this.keywords,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize
        })
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    save(){
      if(this.activeName=='1'){
        this.$nextTick(()=>{
          this.$refs.chanceForm.validate((valid) => {
            if (valid) {
              if(this.losingLottery<0){
                this.$message.error('概率总和不可超出100%')
                return
              }
              this.saveCard()
            } else {
              return false;
            }
          });
        })
      }else if(this.activeName=='2'){
        if(!this.groupForm.list||!this.groupForm.list.length){
          this.$message.error('请添加群聊')
          return 
        }
        this.$nextTick(()=>{
          this.$refs.groupForm.validate((valid) => {
            if (valid) {
              this.saveGroup()
            } else {
              return false;
            }
          });
        })
      }
    },
    async saveGroup(){
      try{
        this.loading = true
        let res = await yilianActivitysave({
          ...this.groupForm,
          YiLianActivityQyWeixinGroupLiveCodeQrCodeDtos:this.groupForm.list
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.getGroupInfo()
        }
      }finally{
        this.loading = false
      }
    },
    async getGroupInfo(){
      try{
        this.loading = true
        let res = await yilianActivityinfo()
        if(res.IsSuccess){
          this.groupForm = {
            ...res.Result,
            list:res.Result.YiLianActivityQyWeixinGroupLiveCodeQrCodeDtos.map(v=>{
              if(!this.groupList.find(x=>x.Id==v.QyWeixinRealGroupId)){
                v.QyWeixinRealGroupId = ''
              }
              return v
            })
          }
        }
      }finally{
        this.loading = false
      }
    },
    async getGroupList(){
      try{
        let res = await qyweixinrealgrouppagelist({
          IsDissolution:false,
          IsQyWeixinRealGroupSelect:true,
          Skip:0,
          Take:9999
        })
        if(res.IsSuccess){
          this.groupList = res.Result.Results
        }
      }finally{
        //
      }
    },
    onSuccess(e,i){
      this.groupForm.list[i].GroupQrCode = e[0]
    },
    beforeUpload(file){
      let type = ['image/png','image/jpg','image/jpeg']
      if(!type.includes(file.type)){
        this.$message.error('图片格式错误')
        return false
      }
      let size = file.size/1024/1024
      if(size>1){
        this.$message.error('图片大小请控制在1M及以内')
        return false
      }
      return true
    },
    async handleClick(e){
      if(e.name=='1'){
        this.getCardInfo()
      }else if(e.name=='2'){
        await this.getGroupList()
        this.getGroupInfo()
      }else if(e.name=='3'){
        this.getDataList()
        this.gettablelist()
      }
    }
  }
}
</script>

<style lang='less' scoped>

.groupBox{
  border: 1px solid #eeeeee;
  padding: 20px 80px 20px 20px;
  background: #fbfbfb;
  flex-shrink: 0;
  display: inline-block;
}
.form-label{
  margin: 0;
  width: 100px;
}
.bottom-button{
  position: fixed;
  right: 0;
  bottom: 0;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left:12%;
  z-index: 99;
  background: white;
}
</style>
<style lang="less">
.EditassociateData-uploadImg{
	width:100%;
	height:100%;
	// z-index: 50;
	position: relative;
	cursor: default;
	img{
		width: 100%;
		height: 100%;
		border-radius: 4px;
    // border: 1px solid black;
	}
	.mask{
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 30px;
		background: rgba(0, 0, 0, .5);
		opacity: 0;
		cursor: pointer;
		// border:1px solid red;
		color: white;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height:1.5;
		.again{
			width: 100%;
			height: 100%;
			// border:1px solid red;
			position: inherit;
			.el-upload{
				// border:1px solid red;
				position: relative;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
			}
		}
	}
	&:hover .mask{
		opacity: 1;
	}
}
</style>